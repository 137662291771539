
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import Login from '@/mixins/login';
import CreateWxacodeMultiDialog from './CreateWxacodeMultiDialog.vue';

const addUnit = (x: string | number) => x + 'px';

@Component({
    components: {
        CreateWxacodeMultiDialog,
    },
})
export default class App extends mixins(Login) {}
